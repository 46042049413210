<template>
  <div class="flex flex-col justify-center space-y-4">
    <FunctionsAll
      v-model:selected-functions="selectedFunctions"
      :search-classes="'sm:mr-8'"
      selectable
      limit-selection-to-one
      limit-selection-to-active
      limit-max-height
      dialog-width
    />

    <div class="flex justify-between">
      <FormButton outlined size="lg" @click="$emit('back')">Back</FormButton>
      <FormButton :disabled="!selectedFunction" size="lg" @click="onFunctionSelected">
        Next
      </FormButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { GetFunctionsItem } from '~/lib/frontend/functions/composables/management'

const emit = defineEmits<{
  (e: 'next', payload: { selectedFunction: GetFunctionsItem }): void
  (e: 'back'): void
}>()

const props = defineProps<{
  initFunction?: GetFunctionsItem
}>()

const selectedFunctions = ref<GetFunctionsItem[]>()
const selectedFunction = computed({
  get: () => selectedFunctions.value?.[0],
  set: (newVal) => {
    selectedFunctions.value = newVal ? [newVal] : undefined
  }
})

const onFunctionSelected = () => {
  if (!selectedFunction.value) return

  emit('next', {
    selectedFunction: selectedFunction.value
  })
}

onMounted(() => {
  if (props.initFunction) {
    selectedFunction.value = props.initFunction
  }
})
</script>
