import { uniqBy } from 'lodash-es'
import {
  usePagedFetch,
  type UsePagedFetchOptions
} from '~/lib/frontend/common/composables/fetch'
import type {
  ModelsOfProject,
  ModelsOfProjectParams,
  ModelsOfProjectQuery
} from '~/server/api/v1/speckleProjects/[projectId]/models/index.get'
import type {
  UserOwnedSpeckleProjects,
  UserOwnedSpeckleProjectsQuery
} from '~/server/api/v1/speckleProjects/index.get'

export const useGetProjects = (params: UserOwnedSpeckleProjectsQuery) =>
  usePagedFetch<
    UserOwnedSpeckleProjects,
    UsePagedFetchOptions<UserOwnedSpeckleProjectsQuery>
  >('/api/v1/speckleProjects', {
    req: {
      query: params
    },
    resultsMergeFn(newData, oldData, reqOptions) {
      const results = {
        ...newData,
        items: uniqBy([...(oldData?.items || []), ...newData.items], (i) => i.id)
      }

      const hasMorePages =
        !!results.cursor && results.cursor !== reqOptions.query.cursor

      return {
        results,
        hasMorePages
      }
    }
  })

export const useGetProjectModels = (params: {
  params: ModelsOfProjectParams | Ref<ModelsOfProjectParams>
  query: ModelsOfProjectQuery
}) =>
  usePagedFetch<ModelsOfProject, UsePagedFetchOptions<ModelsOfProjectQuery>>(
    computed(
      () => `/api/v1/speckleProjects/${unref(params.params).projectId}/models` as const
    ),
    {
      req: {
        query: params.query
      },
      resultsMergeFn(newData, oldData, reqOptions) {
        const results = {
          ...newData,
          items: uniqBy([...(oldData?.items || []), ...newData.items], (i) => i.id)
        }

        const hasMorePages =
          !!results.cursor && results.cursor !== reqOptions.query.cursor

        return {
          results,
          hasMorePages
        }
      }
    }
  )
