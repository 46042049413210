<template>
  <form class="flex flex-col justify-center space-y-4" @submit="onProjectModelSelected">
    <h2 class="h5 font-bold block">Choose Speckle project</h2>
    <p>
      Choose a Speckle project and model onto which you want your automation to be run.
    </p>
    <div class="flex flex-col max-w-sm w-full mx-auto space-y-4">
      <FormSelectProjects
        v-model="selectedProject"
        name="project"
        label="Speckle project"
        :rules="[ValidationHelpers.isRequired]"
        validate-on-value-update
        show-required
        :allow-unset="false"
      />
      <FormSelectModels
        v-if="selectedProject"
        v-model="selectedModel"
        :project-id="selectedProject.id"
        name="model"
        label="Speckle model"
        :rules="[ValidationHelpers.isRequired]"
        validate-on-value-update
        show-required
        :allow-unset="false"
      />
    </div>
    <div class="flex justify-end">
      <FormButton submit :disabled="hasProjectFormErrors" size="lg">Next</FormButton>
    </div>
  </form>
</template>
<script setup lang="ts">
import { ValidationHelpers } from '@speckle/ui-components'
import { useForm } from 'vee-validate'
import type {
  ModelsSelectItemType,
  ProjectsSelectItemType
} from '~/lib/frontend/form/helpers/types'

const emit = defineEmits<{
  (
    e: 'next',
    payload: {
      model: ProjectsSelectItemType
      project: ModelsSelectItemType
    }
  ): void
}>()

const props = defineProps<{
  initSelectedProject?: ProjectsSelectItemType
  initSelectedModel?: ModelsSelectItemType
}>()

const { handleSubmit, errors } = useForm<{
  model: ProjectsSelectItemType
  project: ModelsSelectItemType
}>()

const selectedProject = ref<ProjectsSelectItemType>()
const selectedModel = ref<ModelsSelectItemType>()

const hasProjectFormErrors = computed(() => {
  return Object.keys(errors.value).length > 0
})

const onProjectModelSelected = handleSubmit((values) => {
  emit('next', values)
})

watch(
  selectedProject,
  () => {
    selectedModel.value = undefined
  },
  { flush: 'sync' }
)

onMounted(() => {
  if (props.initSelectedProject) {
    selectedProject.value = props.initSelectedProject
  }
  if (props.initSelectedModel) {
    selectedModel.value = props.initSelectedModel
  }
})
</script>
