<template>
  <form class="flex flex-col justify-center space-y-4" @submit="onMetadataFilled">
    <h2 class="h5 font-bold block">Name your automation</h2>
    <p>Give your automation a name.</p>
    <div class="flex flex-col max-w-sm w-full mx-auto space-y-4">
      <FormTextInput
        v-model="title"
        name="title"
        label="Automation name"
        placeholder="Automation name"
        show-required
        :rules="rules"
        validate-on-value-update
      />
    </div>
    <div class="flex justify-between">
      <FormButton outlined size="lg" @click="$emit('back')">Back</FormButton>
      <FormButton submit :disabled="hasMetadataFormErrors" size="lg">Next</FormButton>
    </div>
  </form>
</template>
<script setup lang="ts">
import { ValidationHelpers } from '@speckle/ui-components'
import { useForm } from 'vee-validate'

const emit = defineEmits<{
  (e: 'next', payload: { title: string }): void
  (e: 'back'): void
}>()

const props = defineProps<{
  initTitle?: string
}>()

const { handleSubmit, errors } = useForm<{
  title: string
}>()

const title = ref('')

const rules = computed(() => [
  ValidationHelpers.isRequired,
  ValidationHelpers.isStringOfLength({ maxLength: 100 })
])

const onMetadataFilled = handleSubmit((values) => {
  emit('next', {
    title: values.title
  })
})

const hasMetadataFormErrors = computed(() => {
  return Object.keys(errors.value).length > 0
})

onMounted(() => {
  if (props.initTitle) {
    title.value = props.initTitle
  }
})
</script>
