<template>
  <div class="flex flex-col justify-center space-y-4">
    <h2 class="h5 font-bold block">Add parameters</h2>
    <p>Fill out parameters defined in the selected function, if any.</p>
    <div v-if="jsonFormSchema">
      <FormJsonForm :schema="jsonFormSchema" @change="onParamsFormChange" />
    </div>
    <CommonAlert v-else color="info">
      <template #title>No parameters defined for the selected function</template>
    </CommonAlert>
    <div class="flex justify-between">
      <FormButton outlined size="lg" @click="$emit('back')">Back</FormButton>
      <FormButton size="lg" :disabled="hasFormErrors" @click="onParamsDone">
        Finish
      </FormButton>
    </div>
  </div>
</template>
<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type { JsonFormsChangeEvent } from '@jsonforms/vue'
import type { GetFunctionsItem } from '~/lib/frontend/functions/composables/management'
import { formattedJsonFormSchema } from '~/lib/frontend/functions/helpers/jsonSchema'

const emit = defineEmits<{
  (e: 'next', payload: { data: any }): void
  (e: 'back'): void
}>()

const props = defineProps<{
  selectedFunction: GetFunctionsItem
}>()

const paramsFormState = ref<JsonFormsChangeEvent>()

const jsonFormSchema = computed(() => {
  const base = props.selectedFunction.functionVersions[0]?.inputSchema
  return formattedJsonFormSchema(base)
})

const hasFormErrors = computed(() => !!paramsFormState.value?.errors?.length)

const onParamsFormChange = (e: JsonFormsChangeEvent) => {
  paramsFormState.value = e
}

const onParamsDone = () => {
  if (hasFormErrors.value) return

  const data = paramsFormState.value?.data || {}
  emit('next', {
    data
  })
}
</script>
