<template>
  <LayoutDialog
    v-model:open="finalOpen"
    max-width="lg"
    prevent-close-on-click-outside
    @fully-closed="onFullyClosed"
  >
    <div v-if="!isReady" class="flex flex-col space-y-4">
      <CommonLoadingIcon class="mx-auto" />
    </div>
    <div v-else class="flex flex-col space-y-4">
      <AutomationsCreateDialogProjectModelStep
        v-if="activeStep === CreateDialogSteps.ProjectModel"
        :init-selected-project="selectedProject"
        :init-selected-model="selectedModel"
        @next="onProjectModelSelected"
      />
      <AutomationsCreateDialogMetadataStep
        v-else-if="activeStep === CreateDialogSteps.Metadata"
        :init-title="title"
        @next="onMetadataFilled"
        @back="onMetadataBack"
      />
      <AutomationsCreateDialogFunctionsStep
        v-else-if="activeStep === CreateDialogSteps.Function"
        :init-function="finalSelectedFunction"
        @next="onFunctionSelected"
        @back="onFunctionsBack"
      />
      <AutomationsCreateDialogParamsStep
        v-else-if="finalSelectedFunction && activeStep === CreateDialogSteps.Params"
        :selected-function="finalSelectedFunction"
        @next="onParamsDone"
        @back="onParamsBack"
      />
      <AutomationsCreateDialogDoneStep
        v-else-if="finalSelectedFunction && activeStep === CreateDialogSteps.Done"
        :selected-function="finalSelectedFunction"
        @view="onViewAutomation"
      />
      <CommonStepsBullet
        v-model="activeStep"
        :steps="steps"
        non-interactive
        steps-padding="sm"
        basic
      />
    </div>
  </LayoutDialog>
</template>
<script setup lang="ts">
import type { BulletStepType } from '@speckle/ui-components'
import { useEncryptionUtils } from '~/composables/crypto'
import { automationRoute } from '~/lib/common/core/helpers/route'
import { getNumericEnumValues } from '~/lib/common/core/helpers/types'
import { CreateDialogSteps } from '~/lib/frontend/automations/helpers/types'
import { useAutomationsStore } from '~/lib/frontend/automations/stores/automations'
import type {
  ModelsSelectItemType,
  ProjectsSelectItemType
} from '~/lib/frontend/form/helpers/types'
import type { GetFunctionsItem } from '~/lib/frontend/functions/composables/management'

const emit = defineEmits<(e: 'update:open', val: boolean) => void>()

const props = defineProps<{
  open: boolean
  preselectedFunction?: GetFunctionsItem
}>()

const automations = useAutomationsStore()
const router = useRouter()
const { encryption, ensure: ensureEncryption, isReady } = useEncryptionUtils()

const finalOpen = computed({
  get: () => props.open,
  set: (newVal) => emit('update:open', newVal)
})

const steps = computed<BulletStepType[]>(() =>
  getNumericEnumValues(CreateDialogSteps)
    .filter((s) => {
      if (props.preselectedFunction) {
        return (s as CreateDialogSteps) !== CreateDialogSteps.Function
      }

      return true
    })
    .map((step) => {
      switch (
        CreateDialogSteps[CreateDialogSteps[step] as keyof typeof CreateDialogSteps]
      ) {
        case CreateDialogSteps.ProjectModel:
          return {
            name: 'Select project'
          }
        case CreateDialogSteps.Function:
          return {
            name: 'Select function'
          }
        case CreateDialogSteps.Params:
          return {
            name: 'Add parameters'
          }
        case CreateDialogSteps.Done:
          return {
            name: 'Done'
          }
        case CreateDialogSteps.Metadata:
          return {
            name: 'Name it'
          }
      }

      return {
        name: ''
      }
    })
)

const title = ref<string>('')
const activeStep = ref<CreateDialogSteps>(CreateDialogSteps.ProjectModel)
const selectedProject = ref<ProjectsSelectItemType>()
const selectedModel = ref<ModelsSelectItemType>()
const selectedFunction = ref<GetFunctionsItem>()
const automationId = ref<string>()

const finalSelectedFunction = computed(
  () => props.preselectedFunction || selectedFunction.value
)

const onProjectModelSelected = (payload: {
  project: ProjectsSelectItemType
  model: ModelsSelectItemType
}) => {
  selectedProject.value = payload.project
  selectedModel.value = payload.model

  if (selectedProject.value && selectedModel.value) {
    activeStep.value = CreateDialogSteps.Metadata
  }
}

const onMetadataBack = () => {
  activeStep.value = CreateDialogSteps.ProjectModel
}

const onMetadataFilled = (payload: { title: string }) => {
  if (!selectedProject.value || !selectedModel.value || !payload.title) return

  title.value = payload.title
  if (props.preselectedFunction) {
    activeStep.value = CreateDialogSteps.Params
  } else {
    activeStep.value = CreateDialogSteps.Function
  }
}

const onFunctionsBack = () => {
  activeStep.value = CreateDialogSteps.Metadata
}

const onFunctionSelected = (payload: { selectedFunction: GetFunctionsItem }) => {
  activeStep.value = CreateDialogSteps.Params
  selectedFunction.value = payload.selectedFunction
}

const onParamsBack = () => {
  if (props.preselectedFunction) {
    activeStep.value = CreateDialogSteps.Metadata
  } else {
    activeStep.value = CreateDialogSteps.Function
  }
}

const onParamsDone = async (payload: { data: unknown }) => {
  const functionId = finalSelectedFunction.value?.functionId
  const versionId = finalSelectedFunction.value?.functionVersions[0]?.functionVersionId
  if (
    !functionId ||
    !versionId ||
    !selectedProject.value ||
    !selectedModel.value ||
    !encryption.value
  )
    return

  if (!automationId.value) {
    const { automationId: newId } = await automations.createAutomation({
      projectId: selectedProject.value.id,
      modelId: selectedModel.value.id,
      automationName: title.value,
      enabled: false
    })
    automationId.value = newId
  }

  const publicKeys = await automations.getPublicKeysAutomation(automationId.value)
  if (!publicKeys || publicKeys.length < 1 || !publicKeys[0]) return //FIXME should we return something more descriptive?
  const encryptor = await encryption.value.buildEncryptor(publicKeys[0])

  await automations.createAutomationVersion(automationId.value, {
    published: true,
    functions: [
      {
        functionId,
        versionId,
        functionInputs: await encryptor.encrypt(JSON.stringify(payload.data || {})),
        encryptionPublicKey: publicKeys[0]
      }
    ]
  })
  await automations.enableAutomation(automationId.value)

  activeStep.value = CreateDialogSteps.Done
}

const onViewAutomation = async () => {
  if (!automationId.value) return
  finalOpen.value = false
  await router.push(automationRoute(automationId.value))
}

const onFullyClosed = () => {
  activeStep.value = CreateDialogSteps.ProjectModel
  selectedProject.value = undefined
  selectedModel.value = undefined
  selectedFunction.value = undefined
  automationId.value = undefined
  title.value = ''
}

// Invoke encryption util loading on first open
watch(
  finalOpen,
  (newOpen, oldOpen) => {
    if (!!newOpen === !!oldOpen) return
    if (!newOpen) return

    if (!isReady.value) {
      void ensureEncryption()
    }
  },
  { immediate: true }
)
</script>
