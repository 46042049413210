<template>
  <FormSelectBase
    ref="select"
    v-model="selectedValue"
    :multiple="multiple"
    :search="true"
    :search-placeholder="searchPlaceholder"
    :get-search-results="invokeSearch"
    :label="label"
    :show-label="showLabel"
    :name="name || 'models'"
    :rules="rules"
    :validate-on-value-update="validateOnValueUpdate"
    :allow-unset="allowUnset"
    :show-required="showRequired"
    mount-menu-on-body
    by="id"
  >
    <template #nothing-selected>
      <template v-if="selectorPlaceholder">
        {{ selectorPlaceholder }}
      </template>
      <template v-else>
        {{ multiple ? 'Select models' : 'Select a model' }}
      </template>
    </template>
    <template #something-selected="{ value }">
      <template v-if="isMultiItemArrayValue(value)">
        <div ref="elementToWatchForChanges" class="flex items-center space-x-0.5">
          <div
            ref="itemContainer"
            class="flex flex-wrap overflow-hidden space-x-0.5 h-6"
          >
            <div v-for="(item, i) in value" :key="item.id" class="text-foreground">
              {{ item.name + (i < value.length - 1 ? ', ' : '') }}
            </div>
          </div>
          <div v-if="hiddenSelectedItemCount > 0" class="text-foreground-2 normal">
            +{{ hiddenSelectedItemCount }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex items-center">
          <span class="truncate text-foreground">
            {{ value.name }}
          </span>
        </div>
      </template>
    </template>
    <template #option="{ item }">
      <div class="flex items-center">
        <span class="truncate">{{ item.name }}</span>
      </div>
    </template>
  </FormSelectBase>
</template>
<script setup lang="ts">
import type { Nullable, Optional } from '@speckle/shared'
import { FormSelectBase, useFormSelectChildInternals } from '@speckle/ui-components'
import type { RuleExpression } from 'vee-validate'
import type { PropType } from 'vue'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
import type { ModelsSelectItemType } from '~/lib/frontend/form/helpers/types'
import { useGetProjectModels } from '~/lib/frontend/speckle/composables/projects'

type ItemType = ModelsSelectItemType
type ValueType = ItemType | ItemType[] | undefined

const emit = defineEmits<(e: 'update:modelValue', v: ValueType) => void>()

const props = defineProps({
  projectId: {
    type: String,
    required: true
  },
  /**
   * Whether to allow selecting multiple items
   */
  multiple: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [Object, Array] as PropType<ValueType>,
    default: undefined
  },
  /**
   * Search placeholder text
   */
  searchPlaceholder: {
    type: String,
    default: 'Search models'
  },
  selectorPlaceholder: {
    type: String as PropType<Optional<string>>,
    default: ''
  },
  /**
   * Label is required at the very least for screen-readers
   */
  label: {
    type: String,
    required: true
  },
  /**
   * Whether to show the label visually
   */
  showLabel: {
    type: Boolean,
    default: false
  },
  name: {
    type: String as PropType<Optional<string>>,
    default: undefined
  },
  rules: {
    type: [String, Object, Function, Array] as PropType<RuleExpression<ValueType>>,
    default: undefined
  },
  validateOnValueUpdate: {
    type: Boolean,
    default: false
  },
  allowUnset: {
    type: Boolean,
    default: true
  },
  showRequired: {
    type: Boolean,
    default: false
  }
})

const select = ref(null as Nullable<{ triggerSearch: () => Promise<void> }>)
// const select2 = ref(null as Nullable<InstanceType<typeof FormSelectBase>>)
// select2.value?.triggerSearch

const elementToWatchForChanges: Ref<Nullable<HTMLElement>> = ref(null)
const itemContainer: Ref<Nullable<HTMLElement>> = ref(null)

const { selectedValue, hiddenSelectedItemCount, isMultiItemArrayValue } =
  useFormSelectChildInternals<ItemType>({
    props: toRefs(props),
    emit,
    dynamicVisibility: { elementToWatchForChanges, itemContainer }
  })

const core = useCoreStore()
const modelsParams = computed(() => ({ projectId: props.projectId }))
const models = useGetProjectModels({
  params: modelsParams,
  query: {
    limit: 10,
    cursor: undefined
  }
})

const invokeSearch = async (search: string) => {
  if (!core.isLoggedIn) return []
  if (!props.projectId) return []

  await models.reset((oldOpts) => ({
    ...oldOpts,
    query: {
      ...oldOpts.query,
      search: search.length ? search : undefined
    }
  }))

  return models.data.value?.items || []
}

watch(modelsParams, () => {
  // Re-trigger search
  if (!select.value) return
  void select.value.triggerSearch()
})

await models.promise
</script>
